import type { RecentlyBilledProjectsQuery } from "~/types/api";
import { useLoaderData } from "@remix-run/react";
import _ from "lodash";
import Alert from "~/components/alert";
import ButtonLink from "~/components/button-link";
import Link from "~/components/link";
import NumberedTable from "~/components/numbered-table";
import Panel from "~/components/panel";
import ProjectTeamCells from "~/components/projects/team-cells";
import { formatMoney, formatNumber } from "~/utils/formatting";

export default function RecentProjects({
  count = 5,
  wrap = true
}: {
  count?: number;
  wrap?: boolean;
}) {
  const {
    recentlyBilledData: { projects }
  } = useLoaderData<{ recentlyBilledData: RecentlyBilledProjectsQuery }>();

  const top = _.orderBy(
    projects,
    (p) => parseFloat(p.recentHours),
    "desc"
  ).slice(0, count);

  const content = top.length ? (
    <NumberedTable>
      <thead>
        <tr>
          <th />
          <th className="text-center">Number</th>
          <th>Name</th>
          <th className="text-center">PC</th>
          <th className="text-center">TL</th>
          <th className="text-center">Exp</th>
          <th className="text-center">PM</th>
          <th className="text-center">Practice Area</th>
          <th>Trade</th>
          <th>Represent</th>
          <th>Client</th>
          <th className="text-right">Recent Hours</th>
          <th className="text-right">Due</th>
        </tr>
      </thead>
      <tbody>
        {top.map((p) => (
          <tr key={p.id}>
            <td />
            <td className="text-center">
              <Link to={`/projects/${p.number}`}>{p.number}</Link>
            </td>
            <td>
              <Link to={`/projects/${p.number}`}>{p.name}</Link>
            </td>
            <ProjectTeamCells project={p} />
            <td className="text-center">
              {p.practiceArea?.displayName || "N/A"}
            </td>
            <td>{p.trade}</td>
            <td>{p.represent}</td>
            <td>
              {p.memberships
                .filter((m) => m.primaryContact)
                .map((m) => (
                  <div key={m.id}>
                    <Link to={`/contacts/${m.contact.id}`}>
                      {m.contact.informalDisplayAs}
                    </Link>
                  </div>
                ))}
            </td>
            <td className="text-right">{formatNumber(p.recentHours)}</td>
            <td className="text-right">{formatMoney(p.totalDue)}</td>
          </tr>
        ))}
      </tbody>
    </NumberedTable>
  ) : (
    <Alert mode="warning">No Recently Billed Projects</Alert>
  );

  return wrap ? (
    <Panel>
      <Panel.Header
        className="text-center"
        title="Top Active Projects (Last 30 Days)"
        floatButton
        button={
          <ButtonLink to="/projects" small className="show-when-hover-parent">
            View Projects
          </ButtonLink>
        }
      />
      {content}
    </Panel>
  ) : (
    content
  );
}
